.fundo{
  background:
          'url("https://st2.depositphotos.com/2931363/8520/i/600/depositphotos_85202978-stock-photo-couple-in-food-store-with.jpg") rgba(255, 255, 255, 0.702)',
        /* // backgroundColor: theme.colors.primaryColor,
        // backgroundImage: `url(${fundo})`,
        background:
          'url("https://st2.depositphotos.com/2931363/8520/i/600/depositphotos_85202978-stock-photo-couple-in-food-store-with.jpg") rgba(255, 255, 255, 0.702)',
        //  background: 'url(http://demos.hacks.mozilla.org/openweb/resources/images/logos/firefox-48.png),
        // -moz-linear-gradient(left, rgba(255, 255, 255, 0),  rgba(255, 255, 255, 1)),
        // url(http://demos.hacks.mozilla.org/openweb/resources/images/patterns/flowers-pattern.jpg)'; */
}
/* .fundo::after{
  content: '';
  background-color: red;
  width: 100vw;
  height: 100vh;
} */
/* .fundo { 
    position: relative; 
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: url('https://placekitten.com/1200/800'), url('https://st2.depositphotos.com/2931363/8520/i/600/depositphotos_85202978-stock-photo-couple-in-food-store-with.jpg');
    background-size: cover;
}

.fundo::before {
    content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-color: rgba(152, 66, 211, 0.63);
} */

/* /* .MuiTextField-root{
  background-color: brown;
  border-color: blueviolet;
  outline-color: pink;
}

.MuiTextField-root:focus{
  background-color: brown;
  border-color: blueviolet;
  outline-color: pink;
}
.MuiTextField-root{
  background-color: blueviolet;
   outline-color: pink;
}
/* MuiInputBase-root 
.MuiInputBase-root{
  background-color: red;
   outline-color: pink;
}

.MuiInputBase-root:focus{
  background-color: pink;
}
.MuiOutlinedInput-root{
  background-color: green;
  border-color: blueviolet;
   outline-color: pink;
}

.MuiOutlinedInput-root:focus{
  background-color: yellow;
  border-color: blueviolet;
   outline-color: pink;
}

MuiOutlinedInput-notchedOutline css-1d3z3hw-MuiOutlinedInput-notchedOutline{
  border-color:beige
} */


fieldset::placeholder{
  border-color: red!important;
}